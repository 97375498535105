import { Component, Input, OnInit } from '@angular/core';

import { DataType, ProfileType } from '@fmlib/enums';
import { ApiService, FilterService } from '@fm/services';
import { Audit, AuditMod, SearchParams, UserFilter } from '@fmlib/interfaces';

import { cloneDeep, sortBy } from 'lodash-es';

import { addSeconds, format, startOfDay } from 'date-fns';

@Component({
	selector: 'audit',
	templateUrl: './audit.component.html',
})
export class AuditComponent implements OnInit {
	@Input() obj;
	@Input() objtype: ProfileType;
	@Input() filters;

	currentDateFilter: UserFilter;
	dateFilters: UserFilter[];

	search;
	selected: Audit;

	constructor(
		private FM_Api: ApiService,
		private FM_Filters: FilterService
	) {}

	ngOnInit(): void {
		// INIT SEARCH
		this.search = {
			isLoading: false,
			isFailed: false,
		};

		// INIT FILTERS
		if (!this.filters) {
			this.filters = {};
		}

		this.dateFilters = this.FM_Filters.getDateFilters();

		// INIT LIST
		this.initList();
	}

	parseArray(item): any[] {
		// ARRAY
		let changes = [],
			parse;

		try {
			parse = JSON.parse(item.value);
		} catch (err) {
			console.error('error parsing', item.value);
		}

		if (item.key === 'broadcastTypes') {
			changes = parse.filter(({ active }) => active);
			changes = changes.map((o) => o.mode);
		} else if (item.key === 'schedules') {
			changes.push(parse.length + ' added');
		} else if (item.key === 'ATTRIBUTES' || item.key === 'PROPERTIES') {
			changes = parse;
		} else {
			changes = parse.map((o) => o.name);
		}

		return changes;
	}

	formatTime(time): string {
		const dayTime = addSeconds(startOfDay(new Date()), parseInt(time, 10));
		return format(dayTime, 'hh:mm aaa');
	}

	parseAudit(item: AuditMod): any {
		let changes, parse;

		changes = [];

		if (item.datatype === 'array') {
			return this.parseArray(item);
		}
		// OBJECT
		else if (item.datatype === 'object') {
			try {
				parse = JSON.parse(item.value);
			} catch (err) {
				console.error('error parsing', item.value);
			}

			if (item.value === '{}') {
				changes.push('---');
			} else if (item.key === 'content') {
				changes.push(parse.url);
			} else {
				changes.push(parse.name);
			}
		}

		// NUMBERS
		else if (item.datatype === DataType.NUMBER) {
			if (item.key === 'startTime' || item.key === 'endTime') {
				const date = this.formatTime(item.value);

				changes.push(date);
			} else {
				changes.push(item.value);
			}
		}

		// STRING
		else {
			if (item.value === 'null' || item.value === '' || item.value === '[]') {
				changes.push('---');
			} else if (item.key === 'tags') {
				try {
					changes = JSON.parse(item.value);
				} catch (err) {
					console.warn('error : parsing', item.value);
				}
			} else if (item.key === 'parent') {
				if (item.value) {
					// GET THE ITEM
					this.FM_Api.connect('companies').get({ id: item.value }, (response) => {
						changes.push(response.name);
					});
				}
			} else if (item.key === 'beaconEvents') {
				try {
					changes = JSON.parse(item.value);
				} catch (err) {
					console.warn('error : parsing', item.value);
				}
				if (changes.length === 0) {
					changes.push('no events');
				}
			} else {
				changes.push(item.value);
			}
		}

		return changes;
	}

	// LOAD ITEM
	selectItem(item: Audit): void {
		// HERE WE NEED TO CLEAN THE AUDIT ITEM
		if (!item.mods_formatted) {
			item.mods_formatted = cloneDeep(item.mods);

			item.mods_formatted.forEach((mod) => {
				mod.key = mod.key.replace(/([A-Z])/g, '_$1').toUpperCase();
				mod.changes = this.parseAudit(mod);
			});

			item.mods_formatted = sortBy(item.mods_formatted, 'key');
		}

		this.selected = item;
	}

	// LOAD LIST
	loadList(): void {
		if (this.search.isLoading || this.search.page > this.search.pages) return;

		this.search.isFailed = false;
		this.search.isLoading = true;

		const params: SearchParams = {
			page: this.search.page,
			sort: '-created',
			conditions: {},
		};

		if (this.filters) {
			params.conditions = cloneDeep(this.filters);
		}

		params.conditions.docId = this.obj.id;
		params.conditions.resource = this.objtype;

		// GET THE AUDITS
		this.FM_Api.connect('audits').query(
			params,
			(data, headers) => {
				this.search.pages = parseInt(headers.get('X-Pages-Total'), 10);
				this.search.total = parseInt(headers.get('X-Records-Total'), 10);
				this.search.page = this.search.page + 1;

				this.search.list = this.search.list.concat(data);
				this.search.count = this.search.list.length;

				if (!this.search.list?.length) {
					delete this.selected;
				} else if (this.search.list?.length && !this.selected) {
					this.selectItem(this.search.list[0]);
				}

				this.search.isLoading = false;
			},
			() => {
				this.search.isLoading = false;
				this.search.isFailed = true;
			}
		);
	}

	initList(): void {
		this.search.page = 1;
		this.search.pages = 1;
		this.search.list = [];
		this.search.count = 0;

		this.loadList();
	}

	// FILTERING
	loadDateFilter(obj: UserFilter): void {
		if (this.currentDateFilter !== obj && obj.filter) {
			this.filters.created = cloneDeep(obj.filter);
			this.currentDateFilter = obj;
		} else {
			delete this.filters.created;
			delete this.currentDateFilter;
		}

		this.initList();
	}

	// FILTERING
	filterDate(obj): void {
		if (obj.filter) {
			const start = new Date(obj.filter.date + '-01-01');
			const end = new Date(obj.filter.date + '-12-31');

			this.filters = {
				created: {
					$gte: start,
					$lt: end,
				},
			};
		} else {
			this.filters = null;
		}

		this.initList();
	}

	filterAction(action?: string): void {
		this.filters.action = action;

		this.initList();
	}

	isActive(f): boolean {
		return this.currentDateFilter && this.currentDateFilter.id === f.id;
	}
}
