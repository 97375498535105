import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProfileType } from '@fmlib/enums';

import { ApiService } from '@fm/services';
import { SharedModule } from '../shared.module';

interface RecentCompany {
	id: string;
	name: string;
}

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'company-quickswitch',
	templateUrl: './company-quickswitch.component.html',
	styleUrls: ['./company-quickswitch.component.less'],
})
export class CompanyQuickswitchComponent implements OnInit {
	@Output() selected = new EventEmitter();

	recentCompanies: RecentCompany[];
	ProfileType = ProfileType;

	constructor(private FM_Api: ApiService) {}

	ngOnInit(): void {
		this.getRecentItems();
	}

	getRecentItems(): void {
		const recents = localStorage.getItem('fm_switched');

		if (recents) {
			const list = JSON.parse(recents);
			if (list?.length) {
				const params = {
					page: 1,
					sort: 'name',
					select: 'name image',
					jedi: true,
					conditions: { id: { $in: list } },
				};

				this.FM_Api.connect('companies').query(params, (data) => {
					this.recentCompanies = [];
					if (data.length) {
						list.forEach((itemId) => {
							const item = data.find(({ id }) => itemId === id);
							if (item) {
								this.recentCompanies.push(item);
							}
						});
					}
				});
			}
		}
	}

	selectItem(item): void {
		this.selected.emit(item);
	}
}
