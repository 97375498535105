import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ProfileType } from '@fmlib/enums';
import { ApiService, GlobalService, SearchService } from '@fm/services';
import { GlobalStore, SearchParams } from '@fmlib/interfaces';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { reject } from 'lodash-es';

@Component({
	selector: 'select-dialog',
	templateUrl: './select-dialog.component.html',
	styleUrls: ['./select-dialog.component.less'],
})
export class SelectDialogComponent implements OnInit, AfterViewInit, OnDestroy {
	global: GlobalStore;

	ProfileType = ProfileType;

	search;
	selected;
	settings: {
		endpoint: any;
		type: ProfileType;
		select: string;
		populate: string;
		filter: any;
		multiselect: boolean;
	};

	private _qsChanged: Subject<string> = new Subject<string>();

	@ViewChild('searchInput', { static: false }) private searchInput: ElementRef;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<SelectDialogComponent>,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService,
		@Inject(SearchService) private FM_Search: SearchService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		// SEARCH OBJECT
		this.search = {
			isLoading: false,
			isFailed: false,
		};

		this.settings = this.FM_Search.clone(this.data.settings);

		switch (this.settings.type) {
			case ProfileType.COMPANY:
				this.settings.endpoint = this.FM_Api.connect('companies');
				this.settings.select = this.FM_Search.selectProps(ProfileType.COMPANY);
				this.settings.populate = 'parent';
				break;
			case ProfileType.EXPERIENCE:
				this.settings.endpoint = this.FM_Api.connect('experiences');
				this.settings.select = this.FM_Search.selectProps(ProfileType.EXPERIENCE);
				// this.settings.populate = FM_Experience.populate();
				break;
			default:
				this.settings.endpoint = this.FM_Api.connect(this.settings.type + 's');
				this.settings.select = this.FM_Search.selectProps(this.settings.type);
		}

		if (this.data.selected) {
			this.selected = this.FM_Search.clone(this.data.selected);
		}

		this._qsChanged.pipe(debounceTime(400), distinctUntilChanged()).subscribe((model) => {
			this.search.qs = model;
			this.initSearch();
		});

		this.initSearch();
	}

	ngAfterViewInit(): void {
		this.searchInput.nativeElement.focus();
	}

	loadList(): void {
		if (this.search.isLoading || this.search.page > this.search.pages) return;

		this.search.isLoading = true;
		this.search.isFailed = false;

		const params: SearchParams = {
			page: this.search.page,
			sort: 'name',
			select: this.settings.select,
			conditions: {},
		};

		if (this.settings.populate) {
			params.populate = this.settings.populate;
		}

		if (this.settings.filter) {
			params.conditions = this.FM_Search.clone(this.settings.filter);
		}

		if (this.settings.type === ProfileType.COMPANY && this.global.user.isSuper) {
			params.jedi = true;
		}

		if (this.search.qs && this.search.qs !== '') {
			params.conditions.searchText = { $regex: this.search.qs.toLowerCase() };
		}

		this.settings.endpoint.query(
			params,
			(data, headers) => {
				this.search.pages = parseInt(headers.get('X-Pages-Total'), 10);
				this.search.total = parseInt(headers.get('X-Records-Total'), 10);

				this.search.list = this.search.list.concat(data);
				this.search.count = this.search.list.length;
				this.search.page = this.search.page + 1;

				this.search.isLoading = false;
			},
			() => {
				this.search.isFailed = true;
				this.search.isLoading = false;
			}
		);
	}

	initSearch(): void {
		this.search.page = 1;
		this.search.pages = 1;
		this.search.list = [];
		this.search.count = 0;

		this.loadList();
	}

	// SELECT ITEM
	selectItem(item): void {
		this.selected = item;
	}

	removeItem(item): void {
		this.selected = reject(this.selected, item);
	}

	clearAll(): void {
		this.selected = [];
	}

	quickSwitch(item): void {
		this.dialogRef.close(item);
	}

	clearQuicksearch(): void {
		this._qsChanged.next('');
	}

	updateSearch(query: string): void {
		this._qsChanged.next(query);
	}

	showProfileImage(): boolean {
		return (
			this.settings.type === ProfileType.APP ||
			this.settings.type === ProfileType.ASSET ||
			this.settings.type === ProfileType.COMPANY ||
			this.settings.type === ProfileType.PAYLOAD
		);
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.selected);
	}

	cancel(): void {
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		this._qsChanged.unsubscribe();
	}
}
