import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'audit-dialog',
	templateUrl: './audit-dialog.component.html',
})
export class AuditDialogComponent {
	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<AuditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	close(): void {
		this.dialogRef.close();
	}
}
